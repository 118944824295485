//Standard libraries
import { Dispatch, useEffect, PropsWithChildren } from 'react'

import Log from './services/Log'
import { fetchCountryListDetails } from './features/account/thunks'
import { initAxios } from './foundation/axios/axiosConfig'
import { useDispatch, useSelector } from 'react-redux'
import { useSite } from './foundation/hooks/useSite'
import { StoreAppContainer } from './layouts/StoreAppContainer'
import { useInitFrameGenius } from '@hooks/useFrameGenius'
import dynamic from 'next/dynamic'
import { localStorageUtil } from '@foundation/utils/storageUtil'
import { loginStatusSelector } from '@features/user/selector'
import { USER_LOGGEDIN_STATUS } from '@foundation/constants/user'
import { useRouter } from 'next/router'

// This is a workaround to init site data on client side
// Needed for apis services, has to to be removed after avoiding get data from site service in apis
const SiteData = dynamic(() => import('./SiteData'), { ssr: false })

const App: React.FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useDispatch<Dispatch<any>>()
  const { mySite } = useSite()
  const userLoggedIn = useSelector(loginStatusSelector) || false

  const router = useRouter()
  const rememberMeEnabled = mySite.xStoreCfg?.REMEMBER_ME_ENABLED

  // Disable WCToken if rememberMe is enabled
  initAxios(dispatch, rememberMeEnabled)
  useInitFrameGenius()

  useEffect(() => {
    localStorageUtil.set(USER_LOGGEDIN_STATUS, userLoggedIn)
  }, [userLoggedIn])

  useEffect(() => {
    const storeId = mySite?.storeID
    if (!storeId) {
      return
    }
    dispatch(
      fetchCountryListDetails({
        storeId,
      })
    )
  }, [dispatch, mySite?.storeID])

  useEffect(() => {
    const handleRouteChange = () => {
      if (window && window.hasOwnProperty('$TB')) {
        window['$TB'].hooks.fireEvent('pageView', null, { sendBeacon: true })
      }
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  Log.info('Init Axios in App.tsx')
  Log.info('App start in App.tsx')

  return (
    <>
      <SiteData mySite={mySite} />
      <StoreAppContainer>{children}</StoreAppContainer>
    </>
  )
}

export default App
